import React from 'react';
import { Card } from 'primereact/card';
import './aboutUsPage.css'

const AboutUsPage = () => {
    return (
        <div className="papa-ji bg-gray-100 mb-11">
            {/* Header Section */}
            <div 
                className="about-us-header text-center mb-12 bg-cover bg-center h-64 flex items-center justify-center"
                style={{ backgroundImage: "url('/images/bg-01.jpg')" }}
            >
                <h1 className="banner-text font-bold text-white bg-opacity-7">About Us</h1>
            </div>

            <div className="our-story flex flex-col lg:flex-row gap-12">
                {/* Our Story Section */}
                <div className="lg:w-1/2">
                    <h2 className="text-3xl font-semibold mb-4">Our Story</h2>
                    <p className="text-gray-600 mb-4">
                    Welcome to Kenara Stores! We’re so glad you’ve found us. Here at Kenara, we believe in bringing you the best in household essentials, beauty products, fashion, and accessories—all carefully selected to enhance your everyday life. Our mission is to provide you with quality products that bring joy, comfort, and style to your home and wardrobe. Whether you’re here to treat yourself or find the perfect gift, we’re committed to making your shopping experience enjoyable and effortless. Thank you for choosing Kenara Stores—where quality meets convenience, and every purchase feels like a little gift to yourself!
                    </p>
                   
                </div>
                
                {/* Image Section for Our Story */}
                <div className="lg:w-1/2 flex justify-center">
                    <img src="/images/blog-01.jpg" alt="Our Story" className="rounded-lg shadow-lg" />
                </div>
            </div>

            {/* Our Mission Section */}
            <div className="our-mission flex flex-col lg:flex-row gap-12 mt-16">
                {/* Image Section for Our Mission */}
                <div className="lg:w-1/2 flex justify-center order-2 lg:order-1">
                    <img src="/images/blog-02.jpg" alt="Our Mission" className="rounded-lg shadow-lg" />
                </div>

                <div className="lg:w-1/2 order-1 lg:order-2">
                    <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
                    <p className="text-gray-600 mb-4">
                      Providing the best quality products to our valued customers...
                    </p>
                    <Card className="border-l-4 border-blue-500 p-4 bg-white shadow-sm mb-4">
                        <p className="text-gray-700 italic">
                            "Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn’t really do it, they just saw something. It seemed obvious to them after a while."
                        </p>
                        <p className="text-right font-semibold mt-2">- Steve Jobs</p>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;
