import MenuBar from "../components/menuBar/menuBar";
import Footer from "../components/footer/footer"
import { CheckoutPage } from "../components/checkoutPage/checkoutPage";

const Checkout = () => {
  return (
    <>
      <MenuBar />
      <CheckoutPage />
      <Footer />
    </>
  );
}

export default Checkout;
