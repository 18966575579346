import AboutUsPage from "../components/aboutUsPage/aboutUsPage";
import Footer from "../components/footer/footer";
import MenuBar from "../components/menuBar/menuBar";

const About = () => {
  return (
      <>
        <MenuBar />
        <AboutUsPage />
        <Footer />
      </>
    );
}
  
export default About;
  