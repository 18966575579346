import { Button } from 'primereact/button';
import { useCart } from '../../hooks/useCart';
import { useNavigate } from 'react-router-dom';
import { CartItems } from './cartItems';
import './cartPage.css'

export const CartPage = () => {
    const navigate = useNavigate()
    const {
        cartItems,
        handleQuantityChange,
        getTotal,
        deleteItem,
    } = useCart()

    return (
        <div className="container mx-auto p-4 lg:flex lg:space-x-8 mb-10">
            <CartItems
                cartItems={cartItems}
                handleQuantityChange={handleQuantityChange}
                deleteItem={deleteItem}
            />
            {/* Cart Totals Section */}
            <div className="bg-white rounded-lg shadow p-4 lg:w-1/3">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Cart Totals</h2>
                <div className="flex justify-between mb-2">
                    <span className="text-gray-700">Subtotal:</span>
                    <span className="text-gray-800 font-semibold">${getTotal()}</span>
                </div>
                <div className="flex flex-col mt-4">
                    <div className="flex justify-between mb-2">
                        <span className="text-gray-700 mb-2">Shipping:</span>
                        <span className="text-gray-800 font-semibold">$0</span>
                    </div>
                </div>
                <div className="border-gray-300 mt-4 pt-4 flex justify-between">
                    <span className="text-gray-700 font-semibold">Total:</span>
                    <span className="text-gray-800 font-semibold">${getTotal()}</span>
                </div>
                <Button
                    label="Proceed to Checkout"
                    onClick={() => navigate('/checkout')}
                    disabled={!cartItems?.length}
                    className="w-full bg-black text-white rounded-lg mt-4 py-3 font-semibold hover:bg-gray-800"
                />
            </div>
        </div>
    );
};

export default CartPage;
