import TestimonialCard from './testimonialCard';
import './testimonials.css'

const Testimonials = () => {
    return (
        <>  
            <p className='text-2xl mb-4 mt-4 pl-12 font-medium'>Testimonials</p>
            <div className='testimonial-container'>
                <TestimonialCard
                    heading="Outstanding Quality!"
                    icon="pi-star-fill"
                    description="The quality of these products is exceptional. I've ordered multiple times and am always impressed with the attention to detail. Highly recommend to anyone looking for something truly unique!"
                />
                <TestimonialCard
                    heading="Fast and Reliable Service"
                    icon="pi-prime"
                    description="From the smooth online ordering process to the quick delivery, everything was seamless. The team is responsive and helpful, making the whole experience so pleasant. I'll definitely be back!"
                />
                <TestimonialCard
                    heading="Highly Recommended!"
                    icon="pi-bookmark-fill"
                    description="I had a few questions before purchasing, and the team went above and beyond to help me out. The product quality and customer support are top-notch. I couldn’t be happier with my purchase!"
                />
            </div>
        </>
    );
}

export default Testimonials