import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { useCart } from '../../hooks/useCart';
import { CartItems } from '../cartPage/cartItems';
import { Dropdown } from 'primereact/dropdown';
import emailjs from 'emailjs-com';
import { countries } from '../../utils/constants';
import './checkoutPage.css'
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

export const CheckoutPage = () => {
    const [customerInfo, setCustomerInfo] = useState({
        fullName: '',
        email: '',
        phone: '',
        deliveryAddress: '',
        country: '',
        state: '',
        postCode: '',
        paymentMethod: 'cashOnDelivery'
    });
    const navigate = useNavigate()

    const isCustomerInfoValid = () => {
        const { fullName, email, phone, deliveryAddress, country, state, postCode } = customerInfo
        
        // Basic validation: check if all required fields are filled
        return (
            fullName.trim() &&
            email.trim() &&
            phone.trim() &&
            deliveryAddress.trim() &&
            country.trim() &&
            state.trim() &&
            postCode.trim()
        )
    }

    const toast = useRef(null);

    const showSuccess = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Order placed successfully',
            life: 3000,
        });
    }
    const {cartItems, handleQuantityChange, refresh} = useCart()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo({ ...customerInfo, [name]: value });
    };

    const handleSubmit = () => {
        try {
            const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
            const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
            const USER_ID = process.env.REACT_APP_USER_ID;
            const messageData = {
                customerData: JSON.stringify(customerInfo, null, 2),
                cartData: cartItems
            }
            const templateParams = {
                message: JSON.stringify(messageData, null, 2),
            };
            emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
        } catch (error) { 
            // No error handling needed 
        } finally {
            localStorage.removeItem('cartItems')
            showSuccess()
            setTimeout(() => {
                refresh()
            }, 4000)
        }
    };

    // If no items in cart redirect to products 
    useEffect(() => {
        const cartData = JSON.parse(localStorage.getItem('cartItems')) || []
        if (!cartData.length) {
            navigate('/products')
        }
    }, [navigate, cartItems.length])
    
    return (
        <div className='checkout-container'>
            <Toast className="" ref={toast} position="top-center"/>
            <div className="checkout-wrapper p-6 bg-white shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">Checkout</h2>

                {/* Customer Information Section */}
                <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">Customer Information</h3>
                    <div className="flex flex-col gap-6">
                        <div className="field">
                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                        <InputText
                            id="fullName"
                            name="fullName"
                            value={customerInfo.fullName}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            placeholder="Enter your full name"
                        />
                        </div>
                        <div className="field">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                        <InputText
                            id="email"
                            name="email"
                            value={customerInfo.email}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            placeholder="Enter your email address"
                        />
                        </div>
                        <div className="field">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                        <InputText
                            id="phone"
                            name="phone"
                            value={customerInfo.phone}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            placeholder="Enter your phone number"
                        />
                        </div>
                    </div>
                </section>

                {/* Delivery Address Section */}
                <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">Delivery Details</h3>
                    <div className="flex flex-col gap-3">
                        <div className="field">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">Country</label>
                            <Dropdown
                                id="country"
                                value={customerInfo.country}
                                options={countries}
                                onChange={(e) => handleInputChange({ target: { name: 'country', value: e.target.value } })}
                                placeholder="Select a country"
                                className="w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">Delivery Address</label>
                            <input
                                type="text"
                                id="deliveryAddress"
                                name="deliveryAddress"
                                value={customerInfo.deliveryAddress}
                                onChange={handleInputChange}
                                placeholder="Delivery address"
                                className="w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            />
                        </div>
                        
                        <div className="field">
                            <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">State / Province</label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                value={customerInfo.state}
                                onChange={handleInputChange}
                                placeholder="Enter your state or province"
                                className="w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            />
                        </div>
                        
                        <div className="field">
                            <label htmlFor="postCode" className="block text-sm font-medium text-gray-700 mb-1">Postcode / Zip</label>
                            <input
                                type="text"
                                id="postCode"
                                name="postCode"
                                value={customerInfo.postCode}
                                onChange={handleInputChange}
                                placeholder="Enter your postcode or zip"
                                className="w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                            />
                        </div>
                    </div>
                </section>

                {/* Payment Method Section */}
                <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">Payment Method</h3>
                    <div className="p-4 bg-gray-50 border border-gray-200 rounded-md shadow-sm">
                        <div className="flex items-center gap-3">
                        <RadioButton
                            inputId="cashOnDelivery"
                            name="paymentMethod"
                            value="cashOnDelivery"
                            onChange={(e) => handleInputChange({ target: { name: 'paymentMethod', value: e.value } })}
                            checked={customerInfo.paymentMethod === 'cashOnDelivery'}
                            className="cursor-pointer"
                        />
                        <label htmlFor="cashOnDelivery" className="text-gray-700 font-medium cursor-pointer">
                            Cash on Delivery
                        </label>
                        </div>
                    </div>
                </section>

                {/* Submit Button */}
                <div className="text-center">
                    <Button
                        label="Place Order"
                        onClick={handleSubmit}
                        disabled={!isCustomerInfoValid()}
                        className="w-full md:w-1/2 p-3 text-lg font-semibold text-white bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 rounded-md shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-400"
                    />
                </div>
            </div>
            <div className='cart-item-wrapper'>
                <CartItems
                    cartItems={cartItems}
                    handleQuantityChange={handleQuantityChange}
                    showCouponBtn={false}
                />
            </div>
        </div>
    );
};
