import { ImageGallery } from '../imageGallery/imageGallery';
import { ProductRightSection } from '../productRightSection/productRightSection';
import ProductCarousel from '../productCarousel/productCarousel';

const SingleProductPage = ({ product }) => {
    return (
        <div className="container mx-auto p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <ImageGallery images={product['product_images']}/>
                <ProductRightSection product={product}/>
            </div>
            <ProductCarousel title="Related Products"/>
        </div>
    );
};

export default SingleProductPage;