import MenuBar from '../components/menuBar/menuBar';
import ProductCarousel from '../components/productCarousel/productCarousel';
import HomepageSlider from '../components/homepageSlider/homepageSlider';
import Testimonials from '../components/testimonials/testimonials';
import Footer from '../components/footer/footer';

const Home = () => {
  return (
    <>
      <MenuBar />
      <HomepageSlider />
      <ProductCarousel title='Featured Products' />
      <Testimonials />
      <Footer />
    </>
  )
}

export default Home;
