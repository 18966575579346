import Footer from "../components/footer/footer";
import MenuBar from "../components/menuBar/menuBar";
import ContactPage from "../components/contactUs/contactPage";

const ContactUs = () => {
    return (
		<>
            <MenuBar />
			<ContactPage />
			<Footer />
        </>
      );
}
  
export default ContactUs;
  