import './testimonials.css'
import './testimonialCard.css'

const TestimonialCard = ({ heading, icon, description }) => {
    return (
        <div className="testimonial-card text-700 text-center">
            <i className={`testimonial-icon pi ${icon}`} />
            <div className="text-900 font-bold text-2xl mb-3">{heading}</div>
            <div className="text-700 mb-5">{description}</div>
        </div>
    );
};

export default TestimonialCard