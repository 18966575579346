import { useState, useEffect } from 'react';

export const useCart = () => {
    const [cartData, setCartData] = useState([]);

    const handleQuantityChange = ({ quantity, itemId }) => {
        const updatedCartData = cartData?.map(item => 
            item.product_id === itemId 
                ? { ...item, productQuantity: quantity } 
                : item
        );
        localStorage.setItem('cartItems', JSON.stringify(updatedCartData));
        refresh()
    };

    const deleteItem = (itemId) => {
        const updatedCartData = cartData?.filter(item => 
            item.product_id !== itemId 
        );
        localStorage.setItem('cartItems', JSON.stringify(updatedCartData));
        refresh()
    };
    
    const getTotal = () => {
        return cartData.reduce(
            (total, item) => total + item['product_price'] * item['productQuantity'],
            0
        ).toFixed(2);
    };

    const addProductToCart = ({ product, quantity, size, color, itemId }) => {
        const existingProducts = JSON.parse(localStorage.getItem('cartItems')) || [];
        const newProduct = { ...product, productQuantity: quantity, size, color };
    
        const productIndex = existingProducts.findIndex(
            (item) => item['product_id'] === newProduct['product_id']
        );
    
        if (productIndex > -1) {
            existingProducts[productIndex]['productQuantity'] = newProduct['productQuantity'];
        } else {
            existingProducts.push(newProduct);
        }

        // Update both localStorage and state
        localStorage.setItem('cartItems', JSON.stringify(existingProducts));
        setCartData(existingProducts);  // Sync state with localStorage
    };

    const refresh = () => {
        const storedItems = localStorage.getItem('cartItems');
        const cartItems = storedItems ? JSON.parse(storedItems) : [];
        setCartData(cartItems);
    }

    useEffect(() => {
        refresh()
    }, []);

    return {
        cartItems: cartData,
        deleteItem,
        handleQuantityChange,
        getTotal,
        addProductToCart,
        refresh
    };
};
