import { useParams } from 'react-router-dom';
import MenuBar from '../components/menuBar/menuBar';
import SingleProductPage from '../components/singleProductPage/singleProductPage';
import { productsData } from '../data/data';
import Footer from '../components/footer/footer';

const ProductDetail = () => {
  const { id: productId } = useParams()
  const product = productsData.find(ele => ele.product_id === productId)

  return (
    <>
      <MenuBar />
      <SingleProductPage product={product} />
      <Footer />
    </>
  )
}

export default ProductDetail
