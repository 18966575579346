import Footer from '../components/footer/footer';
import MenuBar from '../components/menuBar/menuBar';
import ProductCatalog from '../components/productCatalog/productCatalog';

const Products = () => {
  return (
    <>
      <MenuBar />
      <ProductCatalog />
      <Footer />
    </>
  );
}

export default Products;
