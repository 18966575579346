import { Button } from "primereact/button"

export const CartItems = ({cartItems, handleQuantityChange, deleteItem, showCouponBtn = true}) => {
    
    return (
        <>
            {/* Cart Items Section */}
            <div className="flex-1 bg-white rounded-lg shadow p-4 mb-6 lg:mb-0">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Cart Items</h2>
                {cartItems.map(item => (
                    <div key={item['product_id']} className="flex items-center py-4">
                        <img src={item['product_image']} alt={item['product_name']} className="w-20 h-20 object-contain rounded-md mr-4" />
                        <div className="flex-1">
                            <h3 className="text-xs text-gray-700">{item['product_name']}</h3>
                            <p className="text-sm text-gray-600">${item['product_price'].toFixed(2)}</p>
                        </div>
                        <div className='cart-quantity-price'>
                            {showCouponBtn && (
                                <div className="flex flex-col items-center gap-1">
                                    <i className="pi pi-trash" onClick={() => deleteItem(item['product_id'])}/>
                                    <input
                                        type="number"
                                        className="quantity-selector text-sm rounded-l block w-full p-2.5"
                                        required
                                        placeholder='1'
                                        value={item['productQuantity']}
                                        onChange={(e) => handleQuantityChange({
                                            quantity: Math.max(1, Math.min(10, Number(e.target.value))),
                                            itemId: item['product_id']
                                        })}
                                        min={1}
                                        max={10}
                                    />
                                </div>
                            )}
                            <p className="text-gray-700 font-semibold ml-4">${(item['product_price'] * item['productQuantity']).toFixed(2)}</p>
                        </div>
                    </div>
                ))}
                {showCouponBtn && (
                 <div className="mt-4 flex justify-between border-t">
                    <input type="text" placeholder="Coupon" className="border p-2 rounded-lg w-1/2 mr-2" />
                    <Button label="Use code" className="w-1/2 bg-gray-200 text-gray-700 rounded-lg" />
                </div>   
                )}
            </div>
        </>
    )
}