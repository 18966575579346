import { Button } from 'primereact/button';
import './footer.css'

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-gray-400 py-8 pt-20">
            <div className="container mx-auto grid grid-cols-1 custom-lg:grid-cols-4 gap-8">
                {/* Column 1: Categories */}
                <div className='ml-2.5'>
                    <h3 className="font-bold text-gray-200 mb-4">Categories</h3>
                    <ul>
                        <li className="mb-2">Women</li>
                        <li className="mb-2">Men</li>
                        <li className="mb-2">kids</li>
                        <li>Watches</li>
                    </ul>
                </div>

                {/* Column 2: Help */}
                <div className='ml-2.5'>
                    <h3 className="font-bold text-gray-200 mb-4">Help</h3>
                    <ul>
                        <li className="mb-2">Track Order</li>
                        <li className="mb-2">Returns</li>
                        <li className="mb-2">Shipping</li>
                        <li>FAQs</li>
                    </ul>
                </div>

                {/* Column 3: Get in Touch */}
                <div className='ml-2.5'>
                    <h3 className="font-bold text-gray-200 mb-4">Get in Touch</h3>
                    <p className="mb-4">Any questions? Let us know at 502 Stinchcomb Road Hutto Texas 78634 or call us on +1 (518) 870-6622</p>
                    <div className="flex space-x-4">
                        <Button icon="pi pi-facebook" className="p-button-rounded p-button-outlined p-button-secondary" />
                        <Button icon="pi pi-instagram" className="p-button-rounded p-button-outlined p-button-secondary" />
                        <Button icon="pi pi-pinterest" className="p-button-rounded p-button-outlined p-button-secondary" />
                    </div>
                </div>

                {/* Column 4: Newsletter */}
                <div className='ml-2.5 mr-2.5'>
                    <h3 className="font-bold text-gray-200 mb-6">Newsletter</h3>
                    <label for="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">kenarastoresllc@gmail.com</label>
                    <div className="flex items-center space-x-2">
                        <input 
                            type="text" 
                            id="first_name" 
                            className="max-w-[200px] bg-gray-50 border mb-1.5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="Enter your email" 
                            required 
                        />
                        <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                            <span className="relative px-5 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                Submit
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            {/* Footer Bottom Row */}
            <div className="mt-8 text-center text-sm text-gray-500 ml-4 mr-4">
                <div className="flex justify-center space-x-4 mb-4">
                    <i className="footer-icon pi pi-paypal" />
                    <i className="footer-icon pi pi-credit-card" />
                    <i className="footer-icon pi pi-receipt" />
                    <i className="footer-icon pi pi-wallet" />
                    <i className="footer-icon pi pi-apple" />
                </div>
                <p>Copyright © Kenara Store LLC </p>
            </div>
        </footer>
    );
};

export default Footer;
