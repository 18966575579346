import { CartPage } from '../components/cartPage/cartPage';
import Footer from '../components/footer/footer';
import MenuBar from '../components/menuBar/menuBar';

const Cart = () => {
	return (
		<>
			<MenuBar />
			<CartPage />
			<Footer />
		</>
	);
}

export default Cart;
