import { Button } from 'primereact/button';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import { ScreenSize } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import './homepageSlider.css';

const HomepageSlider = () => {
    const isMobile = useIsSmallScreen(ScreenSize.isMobileScreen);
    const navigate = useNavigate()
    const image = isMobile
        ? 'images/landing-page/mobile-landing-page.png'
        : 'images/landing-page/desktop-landing-page.png';

    return (
        <div
            className="background-container"
            style={{ backgroundImage: `url(${image})` }} // Only show the first image
        >
            <div className="slider-content">
               
                <Button
                    label="Shop Now"
                    onClick={() => navigate('/products')}
                    className="animate-zoomIn text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-lg px-6 py-3 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                />
            </div>
        </div>
    );
};

export default HomepageSlider;
