import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import { menuItems } from '../../utils/config';
import './menuBar.css'
// import KenaraStoresLogo from '../../../public/images/icons/kenara-stores-logo.png'

const MenuBar = () => {
    const navigate = useNavigate()

    const start = (
        <div className='text-logo cursor-pointer hover:text-gray-1000' onClick={() => navigate('/')}>
            <p className='logo-part1'>KENARA</p>
            <p className='logo-part2'>STORE</p>
        </div>
    );
    const end = (
        <div
            className="shopping-cart pi pi-shopping-cart text-gray-800 hover:text-gray-600 text-2xl cursor-pointer p-2 rounded-full hover:bg-gray-100 transition-all duration-200"
            onClick={() => navigate('/cart')}
        />
    )

    return (
        <Menubar
            className='custom-menu-bar'
            model={menuItems(navigate)}
            start={start}
            end={end}
        />
    )
}

export default MenuBar