// ContactUs.js
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import './contactPage.css'

const ContactPage = () => {
    return (
        <div className="contact-page-wrapper flex flex-col md:flex-row justify-center items-center min-h-screen bg-gray-50 p-10">
            {/* Left Section - Contact Form */}
            <div className="left-section-container w-full md:w-1/2 p-5">
                <div className="bg-white rounded-lg shadow-lg p-8">
                    <h2 className="text-2xl font-semibold text-center mb-6">Contact Us</h2>
                    <div className="flex flex-col gap-6">
                    <div className="p-input-icon-left">
                        <i className="pl-1.5 pi pi-envelope text-gray-500"></i>
                        <InputText
                            placeholder="Your Email Address"
                            className="w-full pl-7 p-3 rounded-md border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                        />
                    </div>
                        <div>
                            <InputTextarea
                                placeholder="How Can We Help?"
                                rows={5}
                                className="w-full p-3 rounded-md border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200"
                            />
                        </div>
                        <Button label="Submit" className="p-button-secondary w-full py-3 rounded-md text-white bg-blue-600 hover:bg-blue-700" />
                    </div>
                </div>
            </div>

            {/* Right Section - Contact Information */}
            <div className="right-section-container w-full md:w-1/2 p-5">
                <div className="bg-white rounded-lg shadow-lg p-8 text-center md:text-left">
                    {/* Address Section */}
                    <div className="mb-6 flex items-center flex-col">
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                            <i className="pi pi-map-marker text-blue-500 mr-2"></i> Address
                        </h3>
                        <p className="text-gray-600">502 Stinchcomb Road Hutto Texas</p>
                    </div>

                    {/* Phone Section */}
                    <div className="mb-6 flex items-center flex-col">
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                            <i className="pi pi-phone text-blue-500 mr-2"></i> Let’s Talk
                        </h3>
                        <p className="text-blue-500 hover:underline">
                            <a href="tel:+18001236879">+1 (838) 910-7013</a>
                        </p>
                    </div>

                    {/* Email Section */}
                    <div className='flex items-center flex-col'>
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                            <i className="pi pi-envelope text-blue-500 mr-2"></i> Sale Support 
                        </h3>
                        <p className="text-blue-500 hover:underline">
                            <a href="mailto:contact@example.com">omerkhan283@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage