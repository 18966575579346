import { useState, useEffect } from 'react';

const useIsSmallScreen = (width = 500) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < width);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth < width);
        };

        checkScreenSize(); // Check on initial render
        window.addEventListener('resize', checkScreenSize); // Add resize listener

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, [width]);

    return isSmallScreen;
};

export default useIsSmallScreen;
