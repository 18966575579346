import { Galleria } from 'primereact/galleria';
import { galleryResponsiveOption } from '../../utils/config';
import './imageGallery.css'

export const ImageGallery = ({images}) => {
    const itemTemplate = (item) => {
        return <img className='image-item' src={item} alt='alt' style={{ width: '100%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item) => {
        return <img className='thumbnail-item' src={item} alt='alt' />
    }

    return (
        <div className="card"> 
            <Galleria
                value={images}
                style={{ maxWidth: '640px' }}
                showItemNavigators
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
                showThumbnailNavigators={false}
                responsiveOptions={galleryResponsiveOption}
            />
        </div>
    )
}