import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import './productCard.css'

export const ProductCard = ({ product }) => {
    const navigate = useNavigate()

    const header = (
        <img
            src={`../${product['product_image']}`}
            alt={product['product_name']}
        />
    );
    const footer = <h6 className="text-sm">{product['product_displayed_price']}</h6>

    return (
        <Card
            className='product-card'
            title={product['product_name']}
            subTitle={product['product_subtitle'] || ''}
            footer={footer}
            header={header}
            onClick={() => navigate(`/product/${product['product_id']}`)}
        />
    );
};
