import { Carousel } from 'primereact/carousel';
import { Card } from 'primereact/card';
import { productsData } from '../../data/data';
import { responsiveOptions } from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import './productCarousel.css'

const ProductCarousel = ({ title }) => {
    const navigate = useNavigate()
    const productTemplate = (product) => {
        const header = (
            <img
                src={`${product['product_image']}`}
                alt={product['product_name']}
            />
        );
    
        const footer = <h6 className="text-sm">{product['product_displayed_price']}</h6>
    
        return (
            <div
                className="card flex justify-content-center cursor-pointer"
                onClick={() => navigate(`/product/${product['product_id']}`)}
            >
                <Card
                    className='product-template-card'
                    title={product['product_name']}
                    subTitle={product['product_subtitle']}
                    footer={footer}
                    header={header}
                />
            </div>
        );
    };
    return (
        <div className="card">
            <p className='text-2xl mb-4 mt-4 pl-12 font-medium'>{title}</p>
            <Carousel
                value={productsData}
                numVisible={5}
                numScroll={5}
                itemTemplate={productTemplate}
                responsiveOptions={responsiveOptions}
            />
        </div>
    )
}

export default ProductCarousel;
