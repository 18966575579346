

export const productsData = [
    {
        "product_image": "/images/posture corrector.jpg",
        "product_id": "1",
        "product_inventory_status": "INSTOCK",
        "product_name": "Back Brace Posture Corrector",
        "product_subtitle": "fitness",
        "product_displayed_price": "$16.64",
        "product_price": 16.64,
        "product_tag": "men & women",
      
        "product_images": ["/images/posture corrector.jpg"],
        "product_show_related": true,
        "product_desc": "This posture corrector is designed to help improve posture and relieve back pain, making it an essential support tool for those who sit for long hours or experience posture-related discomfort. Made from comfortable, breathable cotton material, it provides a secure fit while remaining lightweight and easy to wear under clothing. The corrector promotes natural alignment by gently pulling the shoulders back, encouraging a straight spine and reducing strain on the neck and back muscles. With easily adjustable straps, it can be customized to fit most body types, ensuring maximum comfort and effectiveness. Ideal for everyday use, this posture corrector aids in building muscle memory for better posture even after it’s removed."
    },
    {
        "product_image": "/images/app 1.webp",
        "product_id": "17",
        "product_inventory_status": "INSTOCK",
        "product_name": "Retractabel Fast car charger",
        "product_subtitle": "gadget",
        "product_displayed_price": "$29.64",
        "product_price": 29.64,
       
        "product_images": ["/images/app 1.webp", "/images/app 1,2.webp"],
        "product_show_related": true,
        "product_desc": "This is a sleek, high-speed car charger with dual USB ports designed to charge multiple devices simultaneously. It features an LED display showing the real-time battery voltage of your car, ensuring safety and monitoring power usage. Its compact, metallic design seamlessly fits into the car’s interior, and the fast-charging ports (with up to 3.1A output each) are compatible with a range of devices, including smartphones, tablets, and other USB-powered gadgets. Ideal for on-the-go charging, this charger provides efficient, reliable power and is a must-have accessory for frequent travelers and commuters."
    },
    {
        "product_image": "/images/royal canin 1.png",
        "product_id": "2",
        "product_inventory_status": "LOWSTOCK",
        "product_name": "Royal canin jelly (pack of 12)",
        "product_subtitle": "pet food",
        "product_displayed_price": "$150",
        "product_price": 150,
        "product_tag": "PET",
        "product_show_related": true,
        "product_images": ["/images/royal canin 1.png"],
        "product_desc": "Royal Canin Persian Adult Wet Cat Food is specially formulated to meet the unique needs of Persian cats, supporting their health and well-being with a delicious and nutritious meal. Made with high-quality ingredients in a smooth loaf-mousse texture, this premium wet food is crafted to suit the short, flat faces of Persian cats, making it easy for them to eat and digest. It contains essential nutrients that promote a healthy coat, skin, and digestive system, tailored specifically for Persian cats’ unique nutritional needs. Ideal for adult Persian cats, this wet food also helps reduce hairballs and keeps your cat’s coat shiny and soft. Treat your Persian to the premium nutrition they deserve with Royal Canin, the trusted choice for feline breed-specific health."
    },
    {
        "product_image": "/images/reflex .png",
        "product_id": "3",
        "product_inventory_status": "low stock",
        "product_name": "Reflex Kitten Food Chicken & Rice 15kg",
        "product_subtitle": "pet food",
        "product_displayed_price": "$99.9",
        "product_price": 99.9,
        "product_tag": "pet",
        "product_images": ["/images/reflex .png"],
        "product_desc": "Reflex Kitten Food Chicken and Rice is a premium cat food containing chicken protein, specially formulated by cat nutritionists to meet all the nutrients needed for the development of kittens. Omega 3 & 6 balance is provided by using natural miracle flaxseed."
    },
    {
        "product_image": "/images/A1.png",
        "product_id": "4",
        "product_name": "Dog Repeller & Anti-Barking Device",
        "product_subtitle": "pet gadget",
        "product_displayed_price": "$75.00",
        "product_price": 75.00,
        "product_tag": "pet",
        "product_show_related": true,
        "product_images": ["/images/A1.png","/images/A11.png","/images/A111.png","/images/A1111.png"],
        "product_desc": "This Dog Repellent Anti-Barking Device with Remote Control is a humane, safe, and effective solution to manage excessive barking and unwanted behaviors in dogs. Using ultrasonic sound technology, this device emits a high-pitched tone that is inaudible to humans but catches your dog’s attention, discouraging barking or other disruptive actions without causing any harm. With a handy remote control, you can easily activate the device up to a distance, allowing you to control barking from across the room or yard. Compact and portable, it’s perfect for use at home, on walks, or during training sessions. Ideal for pet owners and trainers, this device helps improve your dog’s behavior gently and efficiently."
    },
    {
        "product_image": "/images/B1.png",
        "product_id": "5",
        "product_name": "Cat and Dog Grooming kit",
        "product_subtitle": "pet kit",
        "product_displayed_price": "$75",
        "product_price": 75,
        "product_tag": "pet",
        "product_images": ["/images/B2.png","/images/B3.png"]
    },
    {
        "product_image": "/images/product-06.jpg",
        "product_id": "6",
        "product_name": "Vintage Inspired Classic",
        "product_subtitle": "watch",
        "product_displayed_price": "$93.20",
        "product_price": 93.20,
        "product_size": [
            { label: 'Small', value: 'S' },
            { label: 'Medium', value: 'M' },
            { label: 'Large', value: 'L' }
        ],
        "product_tag": "watches",
        "product_show_related": true,
        "product_color": [
            { label: 'Red', value: 'Red' },
            { label: 'Blue', value: 'Blue' },
            { label: 'Green', value: 'Green' }
        ],
        "product_images": ["/images/product-06.jpg"],
    },
    {
        "product_image": "/images/c1.png",
        "product_id": "7",
        "product_name": "Morphe - EYE STUNNERS 6-PIECE",
        "product_subtitle": "Makeup",
        "product_displayed_price": "$72.66",
        "product_price": 72.66,
        "product_tag": "women",
        "product_images": ["/images/c2.png","/images/c3.png","/images/c11.png"],
        "product_desc": "1 Oval Shadow Brush- Pack it in with this thick shadow brush for vivid color with buffed-out edges.2 sCrease Fluff Brush - This fluff brush is perf for working your crease for precise color placement and  blends. 3 Deluxe Blender Brush ) - Make blends meet with this domed brush for effortless transitions that make for a multi-dimensional look.4 Pencil Crease Brush  - This itty-bitty brush brings a big impact for a well-defined crease. 5 Bent Liner Brush  - You’re in control with this bent liner brush that loves to create fine lines. 6 Angle Liner/Spoolie Brush- The dream team has arrived. Fill in those brows with the liner brush and then flip to groom with the spoolie." 
    },
    {
        "product_image": "/images/d1.png",
        "product_id": "8",
        "product_name": "Brazilian Keratin Hair treatment kit",
        "product_subtitle": "hair care",
        "product_displayed_price": "$78.96",
        "product_price": 78.96,
        "product_tag": "women",
        "product_show_related": true,
        "product_images": ["/images/d2.png","/images/d3.png","/images/d4.png","/images/d5.png"],
        "product_desc": "The Cadiveu Brasil Cacau Brazilian Keratin Hair Treatment Smoothing Blowout Kit is a professional-grade solution designed to deliver sleek, smooth, and frizz-free hair with results lasting up to 4 months. This 3-step kit includes an Anti-Residue Shampoo, Eco Keratin Treatment, and Deep Conditioning Mask, each in a generous 1L size, to transform hair with lasting hydration, reconstruction, and shine. Free from harsh chemicals and formaldehyde, this treatment is safe for most hair types and textures, offering deep conditioning and smoothing without compromising hair health. The humidity-resistant, defrizzing formula ensures that hair stays manageable, shiny, and smooth even in challenging weather. After application, clients can immediately resume their normal hair routines, including washing, styling, or using accessories. This keratin treatment simplifies daily styling, reduces frizz, and seals the cuticle, resulting in instantly manageable hair that looks and feels luxurious." 
    },
    {
        "product_image": "/images/e1.png",
        "product_id": "9",
        "product_name": "Cosmetic storage box ",
        "product_subtitle": "Makeup",
        "product_displayed_price": "$55.00",

        "product_price": 55.00,
        "product_tag": "Cosmetics",
        "product_images": ["/images/e1.png","/images/e2.png","/images/e3.png"],
        "product_desc": "This magical cosmetic case is designed to store all your beauty essentials, from cosmetics and skincare products to jewelry and makeup tools. Featuring high-definition transparent drawers, it provides a clear view of the contents at a glance and a rotating design that maximizes storage space. The portable handle makes it easy to carry wherever you go, combining multiple storage functions into one convenient case. With its stylish and practical design, this large-capacity storage box is both waterproof and dustproof, making it the perfect organizer for lipsticks, skincare items, and all your makeup tools in one compact drawer rack."
    },
    {
        "product_image": "/images/f1.png",
        "product_id": "10",
        "product_name": "BABY GIRL COSMETIC MAKEUP",
        "product_subtitle": "kids",
        "product_displayed_price": "$25.85",
        "product_price": 25.85,
        "product_tag": "kids",
        "product_images": ["/images/f1.png","/images/f2.png","/images/f3.png"],
        "product_desc": "Introducing the BABY GIRL COSMETIC MAKEUP SET WITH NAIL ART BRIEFCASE! This high-quality makeup set is perfect for fashion-forward young girls aged 5 years and above. Designed to inspire creativity and imaginative play, this trendy and fashionable cosmetic art briefcase includes all essentials for a fun makeup experience, including nail art. It’s an ideal choice for girls interested in beauty, fashion, and dress-up activities, making it perfect for pretend play. With express delivery available in Islamabad, this makeup set offers quick and convenient access to an exciting beauty play experience, sure to delight any aspiring young makeup artist."},
    {
        "product_image": "/images/g1.png",
        "product_id": "11",
        "product_name": "Punching bag High quality",
        "product_subtitle": "fitness",
        "product_displayed_price": "$123.16",
        "product_price": 123.16,
        "product_tag": "men & women",
        "product_images": ["/images/g1.png","/images/g2.png","/images/g3.png","/images/g4.png"],
        "product_desc": "Elevate your workouts with the 6Ft Freestanding Inflatable Fitness Punching Bag, perfect for both fitness and stress relief. Its freestanding design requires no hanging, making it easy to set up for convenient use anywhere. This inflatable bag comes with an air pump, allowing you to quickly adjust it for flexibility during workouts. Ideal for boxing routines, it helps enhance strength, agility, and coordination. Additionally, it serves as a stress-relief target, offering a fun way to release tension and energy. Suitable for all ages, this versatile punching bag ensures an engaging and effective workout experience for both kids and adults."
    },
    {
        "product_image": "/images/h1.png",
        "product_id": "12",
        "product_name": "Abs king pro",
        "product_subtitle": "fitness",
        "product_displayed_price": "$130.15",
        "product_price": 130.15,
        "product_tag": "men & women",
        "product_images": ["/images/h1.png","/images/h2.png","/images/h3.png","/images/h4.png"],
        "product_desc": "Are you tired of endless gym sessions and exhausting ab workouts? Give your abs and body the royal treatment with the Ab King Pro, one of the easiest and most effective ways to achieve incredible abs and a lean physique. Designed to keep you comfortable while supporting up to 120 kg, this innovative fitness tool targets your upper, middle, and lower abs simultaneously for a highly efficient workout. With four levels of resistance and a new, improved back support, it helps you reduce fat, flatten your stomach, and sculpt incredible abs in just a few weeks. Say goodbye to strenuous routines and hello to a powerful, convenient way to transform your body with the Ab King Pro."
    },
    {
        "product_image": "/images/i1.png",
        "product_id": "13",
        "product_name": "Kids heavy duty jeep",
        "product_subtitle": "toys",
        "product_displayed_price": "$180.49",
        "product_price": 180.49,
        "product_tag": "kids",
        "product_images": ["/images/i1.png","/images/i2.png","/images/i3.png","/images/i4.png","/images/i5.png"],
        "product_desc": "This battery-operated full-size 4x4 jeep is the ultimate outdoor toy for children aged 4-10 years. Designed with a trendy, modern look, it captures the excitement of a real 4x4 vehicle, making it a favorite for kids who love adventure and outdoor play. Equipped with the latest features, this jeep can handle a variety of terrains, encouraging children to explore and stay active outdoors. With express delivery available in Lahore, this model 555 jeep is ready to bring hours of fun and excitement to any young driver’s playtime, making it an ideal gift for energetic kids."
    },
    {
        "product_image": "/images/i7.png",
        "product_id": "14",
        "product_name": "KIDS BATTERY OPERATED BIG SIZE 2 WHEELER",
        "product_subtitle": "toys",
        "product_displayed_price": "$154.79",
        "product_price": 154.79,
        "product_tag": "kids",
        "product_images": ["/images/i7.png"],
        "product_desc": "Battery-operated ride-on bikes and scooters for kids are popular, providing a fun way for children to enjoy outdoor play. Ride-on bikes feature rechargeable batteries, adjustable speed settings (2-6 mph), realistic designs resembling motorcycles or ATVs, and safety features like training wheels, speed limiters, and seat belts. Easy to operate with foot pedals and handlebars, many also include music, sound effects, and LED lights. Scooters, often lower in speed (3-5 mph), are compact and foldable, available in bright colors, and include stability features like three-wheel designs, braking systems, and adjustable handlebars. Both options can be found at toy stores, online retailers, and department stores. When choosing, consider the child’s age, size, and safety features like speed controls, stabilizers, and warranties, and check customer reviews for durability. Always supervise and equip kids with helmets and knee pads for safe riding."
    },
    {
        "product_image": "/images/product-15.jpg",
        "product_id": "15",
        "product_name": "Mini Silver Mesh Watch 2",
        "product_subtitle": "watch",
        "product_displayed_price": "$86.85",
        "product_price": 86.85,
        "product_size": [
            { label: 'Small', value: 'S' },
            { label: 'Medium', value: 'M' },
            { label: 'Large', value: 'L' }
        ],
        "product_tag": "watches",
        "product_color": [
            { label: 'Red', value: 'Red' },
            { label: 'Blue', value: 'Blue' },
            { label: 'Green', value: 'Green' }
        ],
        "product_images": ["/images/product-15.jpg"],
    },
    {
        "product_image": "/images/i8.png",
        "product_id": "16",
        "product_name": "mini tool kit for house",
        "product_subtitle": "tools",
        "product_displayed_price": "$59.64",
        "product_price": 59.64,
        "product_tag": "household",
        "product_images": ["/images/i8.png"],
        "product_desc": "MULTI PURPOSE TOOLKIT HOUSE HOLD KIT ELECTRIC TOOL KIT"
    },
    {
        "product_image": "/images/j1.png",
        "product_id": "17",
        "product_name": "Barbie : Malibu House",
        "product_subtitle": "Kids",
        "product_displayed_price": "$229.64",
        "product_price": 229.64,
        "product_tag": "Kids",
        "product_images": ["/images/j1.png","/images/j2.png","/images/j3.png","/images/j44.png","/images/j5.png"],
        "product_desc": "The Barbie Malibu House is a 2-story dollhouse that opens up to over 2 feet wide, featuring 6 rooms including a kitchen, dining room, living room, bedroom, bathroom, and outdoor patio. With cool transformation features, the living room turns into an entertainment space, the bathroom vanity reveals a shower, and the bedroom ceiling converts into a bunk bed for sleepovers. Kids can arrange and rearrange 25+ accessories, including furniture like a sofa, coffee table, and dining set, plus smaller items like place settings and game boards. Perfect for imaginative play, this dollhouse offers endless storytelling possibilities. Dolls are sold separately.",
    }
]