export const responsiveOptions = [
    {
        breakpoint: '2000px', // Very large screens
        numVisible: 8,
        numScroll: 8
    },
    {
        breakpoint: '1900px', // Very large screens
        numVisible: 8,
        numScroll: 8
    },
    {
        breakpoint: '1800px', // Very large screens
        numVisible: 8,
        numScroll: 8
    },
    {
        breakpoint: '1700px', // Very large screens
        numVisible: 7,
        numScroll: 7
    },
    {
        breakpoint: '1650px', // Extra large screens
        numVisible: 6,
        numScroll: 6
    },
    {
        breakpoint: '1600px', // Extra large screens
        numVisible: 6,
        numScroll: 6
    },
    {
        breakpoint: '1550px', // Extra large screens
        numVisible: 6,
        numScroll: 6
    },
    {
        breakpoint: '1500px', // Extra large screens
        numVisible: 6,
        numScroll: 6
    },
    {
        breakpoint: '1400px',
        numVisible: 6,
        numScroll: 6
    },
    {
        breakpoint: '1300px',
        numVisible: 6,
        numScroll: 6
    },
    {
        breakpoint: '1250px', // Large screens (desktop)
        numVisible: 6, // Show 5 items
        numScroll: 6, // Scroll 5 items at a time
    },
    {
        breakpoint: '1200px', // Large screens (desktop)
        numVisible: 5, // Show 5 items
        numScroll: 5, // Scroll 5 items at a time
    },
    {
        breakpoint: '1024px', // Tablets
        numVisible: 4, // Show 4 items
        numScroll: 4, // Scroll 4 items at a time
    },
    {
        breakpoint: '768px', // Small tablets or large mobile
        numVisible: 3, // Show 3 items
        numScroll: 3, // Scroll 3 items at a time
    },
    {
        breakpoint: '550px', // Small tablets or large mobile
        numVisible: 2, // Show 3 items
        numScroll: 2, // Scroll 3 items at a time
    },
    {
        breakpoint: '480px', // Mobile view
        numVisible: 1, // Show 1 item
        numScroll: 1, // Scroll 1 item at a time
    },
    {
        breakpoint: '360px', // Extra small mobile view
        numVisible: 1, // Show 1 item (could be more responsive)
        numScroll: 1, // Scroll 1 item at a time
    },
];

export const menuItems = (navigate) => {
    return [
        {
            label: 'Home',
            icon: 'pi pi-home',
            command: () => navigate('/')
        },
        {
            label: 'Products',
            icon: 'pi pi-star',
            command: () => navigate('/products')
        },
        {
            label: 'Contact',
            icon: 'pi pi-envelope',
            command: () => navigate('/contact-us')
        },
        {
            label: 'About Us',
            icon: 'pi pi-user',
            command: () => navigate('/about')
        }
    ];
}

export const galleryResponsiveOption = [
    {
        breakpoint: '2000px',
        numVisible: 6
    },
    {
        breakpoint: '1900px',
        numVisible: 6
    },
    {
        breakpoint: '1800px',
        numVisible: 6
    },
    {
        breakpoint: '1700px',
        numVisible: 6
    },
    {
        breakpoint: '1600px',
        numVisible: 6
    },
    {
        breakpoint: '1400px',
        numVisible: 5
    },
    {
        breakpoint: '991px',
        numVisible: 4
    },
    {
        breakpoint: '767px',
        numVisible: 4
    },
    {
        breakpoint: '575px',
        numVisible: 4
    }
];
