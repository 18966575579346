import { productsData } from "../../data/data";
import { ProductCard } from "../productCard/productCard";
import './productCatalog.css'

const ProductCatalog = () => {
    const products = productsData
    return (
        <div className="all-products">
            {products.map(product => <ProductCard product={product}/>)}
        </div>
    );
}

export default ProductCatalog