import { useRef, useState } from "react";
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Toast } from 'primereact/toast';
import { useCart } from "../../hooks/useCart";
import './productRightSection.css'

export const ProductRightSection = ({ product }) => {
    const [quantity, setQuantity] = useState(1);
    const [size, setSize] = useState(null);
    const [color, setColor] = useState(null);
    const toast = useRef(null);
    const {
        addProductToCart
    } = useCart()

    const showSuccess = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Product Added to cart',
            life: 3000,
        });
    }

    const addToCart = ({ product, quantity, size, color }) => {
        addProductToCart({product, quantity, size, color, itemId: product['product_id']})
        showSuccess()
    }

    return (
        <>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <Toast className="" ref={toast} position="top-center"/>

                {/* Product Name and Price */}
                <h2 className="text-3xl font-bold text-gray-800 mb-1">{product['product_name']}</h2>
                <p className="text-xl text-gray-700 font-semibold mb-4">{product['product_displayed_price']}</p>
                        
                {/* Product Description */}
                <p className="text-gray-600 mb-6">{product['product_desc']}</p>

                {/* Size and Color Selection */}
                <div className="space-y-4">
                    {/* Size Selector */}
                    {product['product_size'] && (
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Size</label>
                            <Dropdown
                                value={size}
                                onChange={(e) => setSize(e.value)}
                                options={product['product_size']}
                                placeholder="Select Size"
                                className="w-full md:w-1/2 rounded-md border-gray-300 shadow-sm"
                            />
                        </div>
                    )}

                    {/* Color Selector */}
                    {product['product_color'] && (
                        <div>
                            <label className="block text-gray-700 font-medium mb-2">Color</label>
                            <Dropdown
                                value={color}
                                onChange={(e) => setColor(e.value)}
                                options={product['product_color']}
                                placeholder="Select Color"
                                className="w-full md:w-1/2 rounded-md border-gray-300 shadow-sm"
                            />
                        </div>
                    )}

                </div>

                {/* Add to cart */}
                <div className="flex items-center space-x-4 mt-6">
                    <div>
                        <input 
                            type="number" 
                            placeholder='1'
                            className="quantity-selector text-sm rounded-l block w-full p-2.5"
                            required 
                            onChange={(e) => setQuantity(Math.max(1, Math.min(10, Number(e.target.value))))} 
                            min={1}
                            max={10}
                        />
                    </div>
                    <Button
                        label="Add to Cart" 
                        onClick={() => addToCart({product, quantity, size, color})}
                        className="add-to-cart text-sm bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600 transition duration-300 ease-in-out" 
                    />
                </div>

                {/* Social Media Icons */}
                <div className="flex items-center justify-start space-x-6 mt-6 text-gray-600">
                    <i className="pi pi-heart cursor-pointer hover:text-red-500 transition duration-300 ease-in-out"></i>
                    <i className="pi pi-facebook cursor-pointer hover:text-blue-600 transition duration-300 ease-in-out"></i>
                    <i className="pi pi-twitter cursor-pointer hover:text-blue-400 transition duration-300 ease-in-out"></i>
                    <i className="pi pi-google cursor-pointer hover:text-red-600 transition duration-300 ease-in-out"></i>
                </div>
            </div>
        </>
    )
}